// src/App.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';

function App() {
  const [originalUrl, setOriginalUrl] = useState('');
  const [customUrl, setCustomUrl] = useState('');
  const [shortUrl, setShortUrl] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setShortUrl('');

    try {
      const response = await axios.post('https://shortnerapi.hmmbo.com/shorten', {
        originalUrl,
        customUrl,
      });
      setShortUrl(response.data.shortUrl);
    } catch (err) {
      setError(err.response?.data?.error || 'Something went wrong.');
      console.log(err);
    }
  };

  useEffect(() => {
    const checkPath = async () => {
      const path = window.location.pathname; // Get the current path
      if (path.startsWith('/urls/')) {
        const shortUrlParam = path.split('/').pop(); // Extract the short URL parameter
        try {
          const response = await axios.get(`https://shortnerapi.hmmbo.com/urls/${shortUrlParam}`);
          // Redirect to the original URL from the response
          window.location.href = response.data.originalUrl;
        } catch (err) {
          console.error('Error fetching original URL:', err);
          // Optionally handle errors, like showing a not found message
        }
      }
    };

    checkPath();
  }, []);

  return (
    <Router>
      <div className="app-container">
        <div className="card">
          <h1>URL Shortener</h1>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Enter original URL"
              value={originalUrl}
              onChange={(e) => setOriginalUrl(e.target.value)}
              required
            />
            <input
              type="text"
              placeholder="Enter custom alias (optional)"
              value={customUrl}
              onChange={(e) => setCustomUrl(e.target.value)}
            />
            <button type="submit">Shorten URL</button>
          </form>

          {error && <p className="error">{error}</p>}
          {shortUrl && (
            <p className="success">
              Shortened URL: <a href={shortUrl} target="_blank" rel="noopener noreferrer">{shortUrl}</a>
            </p>
          )}
          
        </div>
        
      </div>
    </Router>
  );
}

export default App;
